'use client';

import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { useEffect, useState } from 'react';

export default function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const hasHiddenClass = () => {
		if (!isVisible) return 'hidden';
		return;
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="z-100 fixed bottom-3 right-3 sm:bottom-8 sm:right-10">
			<Button
				prefixIcon={<FontAwesomeIcon icon={faArrowToTop} />}
				onClick={scrollToTop}
				variant="secondary"
				className={`items-center justify-center rounded-lg bg-gray-50  transition-all duration-300 ${hasHiddenClass()}`}
			>
				Back to Top
			</Button>
		</div>
	);
}
