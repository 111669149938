import(/* webpackMode: "eager" */ "/app/apps/tournament/src/app/_components/scroll-to-top.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/FullSearchForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/homepage/FeaturedContainer.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/homepage/SliderSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/homepage/TopButtonBar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/ImpressionWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/MobileSearchHeader.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/RenderAd.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/legacy/modules/main-header/MainHeader.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/legacy/modules/top-navigation/TopNavigation.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/tournament/components/PPATournamentCarousel.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.2/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.2/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/packages/features/src/search-header/components/SearchHeader.tsx")