'use client';

import { Button } from '@pickleballinc/react-ui';
import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { useSession } from '@/legacy/lib/SessionProvider';
import isSsoURL from '@/utils/helpers';

interface TopButtonBarProps {
	createPerms: {
		ApprovedToCreate: boolean;
		URLPath: string;
		LoggedIn: boolean;
	};
}

export const TopButtonBar = ({ createPerms }: TopButtonBarProps) => {
	const [createATournamentURL, setCreateATournamentURL] = useState<Url>('');
	const [registeredForURL, setRegisteredForURL] = useState<Url>('');
	const [managingURL, setManagingURL] = useState<Url>('');

	const { session } = useSession();

	// Managing URL
	useEffect(() => {
		const destinationUrl = new URL(`${process.env.NEXT_PUBLIC_PT_URL}/search?my_tournaments=true&managing=true`);

		if (session?.user && session?.user?.uuid) {
			setManagingURL(destinationUrl.toString());
		} else {
			const url = new URL(`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin`);
			url.searchParams.set('flow', 'SelfService');
			url.searchParams.set('continue', destinationUrl.toString());
			setManagingURL(url);
		}
	}, []);

	useEffect(() => {
		const destinationUrl = new URL(`${process.env.NEXT_PUBLIC_PT_URL}/search?my_tournaments=true`);

		if (session?.user && session?.user?.uuid) {
			setRegisteredForURL(destinationUrl.toString());
		} else {
			const url = new URL(`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin`);
			url.searchParams.set('flow', 'SelfService');
			url.searchParams.set('continue', destinationUrl.toString());
			setRegisteredForURL(url);
		}
	}, []);

	// Create a Tournament URL
	useEffect(() => {
		if (session?.user && session?.user?.uuid && createPerms?.ApprovedToCreate) {
			const url = new URL(createPerms.URLPath);
			url.searchParams.set('rurl', window.location.href);
			setCreateATournamentURL(url);
		} else {
			const url = new URL(`https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`);

			url.hostname = `contact.${url.hostname}`;
			url.pathname += 'contacts/tournament/create';

			setCreateATournamentURL(url);
		}
	}, [createPerms]);

	return (
		<div className="mx-auto grid max-w-screen-2xl grid-cols-2 gap-4 p-4 sm:grid-cols-6 sm:items-center md:flex md:grid-cols-1 md:flex-row md:flex-wrap">
			<a
				href={`${process.env.NEXT_PUBLIC_PT_URL}/search`}
				className={`col-span-2 ${session?.user?.uuid ? 'sm:col-span-6' : 'sm:col-span-3'}`}
				aria-label="Find a Tournament"
			>
				<Button size="xl" variant="blue" className="w-full justify-center font-bold" aria-hidden="true">
					Find a Tournament
				</Button>
			</a>
			<>
				<a
					className="col-span-1 sm:col-span-3"
					href={managingURL.toString()}
					rel={isSsoURL(managingURL) ? 'nofollow' : undefined}
					aria-label="Managing a Tournament"
				>
					<Button size="xl" variant="secondary" className="w-full justify-center font-bold" aria-hidden="true">
						Managing
					</Button>
				</a>
				<a
					className="col-span-1 sm:col-span-3"
					href={registeredForURL.toString()}
					rel={isSsoURL(registeredForURL) ? 'nofollow' : undefined}
					aria-label="Registered For a Tournament"
				>
					<Button size="xl" variant="secondary" className="w-full justify-center font-bold" aria-hidden="true">
						Registered For
					</Button>
				</a>
			</>

			{/* <Button variant="secondary" size="xl">
            <div>
                <FontAwesomeIcon icon={faHeart} />
            </div>
        </Button> */}

			<Link
				className={`col-span-2 md:ml-auto ${session?.user?.uuid ? 'sm:col-span-6' : 'sm:col-span-3'}`}
				href={createATournamentURL}
				rel={isSsoURL(createATournamentURL) ? 'nofollow' : undefined}
				aria-label="Create a Tournament"
			>
				<Button size="xl" className="w-full justify-center" variant={session?.user?.uuid ? 'blue' : 'secondary'} aria-hidden="true">
					Create a Tournament
				</Button>
			</Link>
		</div>
	);
};
