'use client';

import Image from 'next/image';
import { ReactNode } from 'react';

import { ImpressionWrapper } from '@/components/ImpressionWrapper';
import { SystemModuleType } from '@/utils/types';

export interface TournamentHomeCardProps {
	isRecommendedTournament?: boolean;
	image: string;
	title: string;
	duration?: string;
	slug?: string;
	tournamentId: string;
	location?: string;
	label?: ReactNode;
	registrationCount?: number;
	isPromoted?: boolean;
}

export const FeaturedTournamentCard = ({ title, image, duration, location, tournamentId }: TournamentHomeCardProps) => {
	return (
		<ImpressionWrapper moduleId={tournamentId} moduleFor={SystemModuleType.FEATURED_TOURNAMENT}>
			<div className="flex h-full flex-row flex-wrap gap-2 rounded-lg border bg-white p-2 md:flex-nowrap">
				<div className="relative m-auto h-40 w-full !rounded-lg md:h-20 md:w-1/3">
					<Image
						src={image}
						alt={title}
						fill
						priority
						className="rounded-lg object-contain"
						sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
					/>
				</div>
				<div className="flex flex-col justify-between md:w-2/3">
					<div>
						<div className="text-sm text-gray-500">{duration}</div>
						<div className="line-clamp-2 text-sm font-medium text-gray-700">{title}</div>
					</div>
					{location && <div className="text-sm font-medium text-gray-500"> {location} </div>}
				</div>
			</div>
		</ImpressionWrapper>
	);
};
